import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Screens} from '../../constants';
import {
  AssignToAHeatProfileScreen,
  EpilepsyWarningScreen,
  HeatProfileCreateScreen,
  HeatProfileEditScreen,
  MoodLightControllerScreen,
} from '../../screens';
import {defaultNavigationOptions} from './common';
import {WithRedirect} from './params';

export type HeatProfileEditStackParamList = {
  [Screens.HeatProfileCreate]:
    | {
        profileId?: string;
        headerTitle?: string;
        showHeader?: boolean;
        openMoodLight?: boolean;
      }
    | undefined;
  [Screens.HeatProfileEdit]:
    | {
        profileId?: string;
        headerTitle?: string;
        showHeader?: boolean;
        openMoodLight?: boolean;
      }
    | undefined;
  [Screens.MoodLightController]: undefined;
  [Screens.EpilepsyWarning]: WithRedirect;
  [Screens.AssignToAHeatProfile]: undefined;
};

const HeatProfileEditStack =
  createStackNavigator<HeatProfileEditStackParamList>();

export const HeatProfileEditStackNavigator = () => {
  return (
    <HeatProfileEditStack.Navigator
      screenOptions={{...defaultNavigationOptions, headerTitle: ''}}>
      <HeatProfileEditStack.Screen
        name={Screens.HeatProfileCreate}
        initialParams={{showHeader: true}}
        component={HeatProfileCreateScreen}
      />

      <HeatProfileEditStack.Screen
        name={Screens.HeatProfileEdit}
        initialParams={{showHeader: true}}
        component={HeatProfileEditScreen}
      />

      <HeatProfileEditStack.Screen
        name={Screens.MoodLightController}
        component={MoodLightControllerScreen}
        options={{gestureEnabled: false}}
      />

      <HeatProfileEditStack.Screen
        name={Screens.EpilepsyWarning}
        component={EpilepsyWarningScreen}
        options={{headerShown: false}}
      />

      <HeatProfileEditStack.Screen
        name={Screens.AssignToAHeatProfile}
        // @ts-expect-error because of the screen being part of two separate stacks, the type can not be infered correctly
        component={AssignToAHeatProfileScreen}
      />
    </HeatProfileEditStack.Navigator>
  );
};
