import {
  Analytics as FirebaseAnalyticsType,
  getAnalytics,
  logEvent,
  setUserId,
} from 'firebase/analytics';

import {firebaseApp} from '../../firebase/firebase.web';
import {EventProperties, EventType} from '../event-properties';
import {Analytics} from '../types';
import {formatType} from './common';

export class FirebaseAnalytics implements Analytics {
  private analytics: FirebaseAnalyticsType | undefined;

  constructor() {
    this.analytics = undefined;
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    if (!this.analytics) return;

    return logEvent(this.analytics, formatType(type), event);
  }

  setCurrentUser(userId?: string): void {
    if (!this.analytics) return;

    return setUserId(this.analytics, userId as string);
  }

  trackUser() {}

  setEnabled(enabled: boolean): void {
    // On web we can't disabled analytics thorugh the sdk
    if (!enabled) return;

    this.analytics = getAnalytics(firebaseApp);
  }
}
