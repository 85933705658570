import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {backgroundReferAFriend} from '../../assets/images';
import {HeadingText} from '../../components';
import {
  Constants,
  Messages,
  Screens,
  Strings,
  appColors,
  interpolate,
} from '../../constants';
import {useReferralConfig} from '../../lib/hooks/useReferralConfig';
import {useReferralLink} from '../../lib/hooks/useReferralLink';
import {addOpacityToColorHex} from '../../lib/utilityFunctions';
import {shareUrl} from '../../lib/utilityFunctions/shareUrl';
import {ReferralTabNavigatorScreenProps} from '../../navigation/navigators/ReferralProgramStack';
import {LinearGradient} from '../../shims/LinearGradient';
import {
  CtaButton,
  ImageHeader,
  ScrollLayout,
  SupportSection,
} from './components';

interface ScreenProps
  extends ReferralTabNavigatorScreenProps<typeof Screens.ReferAFriend> {}

const screenHeight = Constants.DIMENSIONS.HEIGHT;

export const ReferAFriendScreen: React.FC<ScreenProps> = () => {
  const {data: url, isLoading} = useReferralLink();

  const {data: config} = useReferralConfig();

  const amount = config?.amount;
  const cartMinimum = config?.cartMinimum;

  const onShare = async () => {
    if (!url) return;

    await shareUrl(
      {
        url: url,
        title: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND,
        message: interpolate(Messages.REWARDS.USE_MY_LINK, {amount}),
      },
      {subject: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND},
    ).catch(() => void 0);
  };

  return (
    <View style={styles.layout}>
      <ScrollLayout>
        <ImageHeader source={backgroundReferAFriend} />

        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <HeadingText style={styles.title}>
              {Messages.REWARDS.REFER_REWARD_REPEAT}
            </HeadingText>

            <Text style={styles.subtitle}>
              {interpolate(Messages.REWARDS.GET_AMOUNT, {amount})}
            </Text>
          </View>

          <View style={styles.footer}>
            <CtaButton
              title={Strings.REFERRAL_PROGRAM.SHARE_REFERRAL}
              style={styles.ctaButton}
              onPress={onShare}
              disabled={isLoading}
            />

            <SupportSection />

            <Text style={styles.footerText}>
              {interpolate(Messages.REWARDS.FOOTER_NOTE1, {
                amount,
                cartMinimum,
              })}
            </Text>
          </View>
        </View>
      </ScrollLayout>

      <LinearGradient
        style={{position: 'absolute', width: '100%', bottom: 0, height: 10}}
        colors={['transparent', addOpacityToColorHex(appColors.black, 0.8)]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    flexBasis: 0,
  },
  contentContainer: {
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    paddingTop: '8%',
    paddingHorizontal: 24,
  },
  title: {
    marginBottom: '5%',
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    color: appColors.textOnSurface,
    lineHeight: 26,
    fontSize: 16,
    marginBottom: '5%',
  },
  questionText: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    letterSpacing: 0.32,
  },
  contactSupportText: {
    color: appColors.white,
    fontSize: 16,
    letterSpacing: 0.32,
    lineHeight: 26,
  },
  ctaButton: {
    width: '100%',
  },
  footer: {
    width: '100%',
    marginTop: '10%',
    gap: screenHeight * 0.04,
    paddingBottom: Constants.IS_NATIVE_ANDROID ? 0 : screenHeight * 0.1,
  },
  footerText: {
    fontSize: 14,
    color: appColors.white,
    fontFamily: 'Roboto-Regular',
    lineHeight: 22,
    opacity: 0.5,
    paddingBottom: Constants.IS_NATIVE_ANDROID ? 108 : 48,
  },
});
