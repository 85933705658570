import React from 'react';

import {StyledSlider, StyledSliderProps} from '../../components';
import {appColors} from '../../constants';
import {HandlerProps, SlideHandler} from '../components';

export interface Props
  extends Pick<HandlerProps<number>, 'initialValue' | 'update'>,
    Pick<
      StyledSliderProps,
      'maximumValue' | 'minimumValue' | 'paddingBottom' | 'titleMarginBottom'
    > {}

export const TemperatureSlider = ({initialValue, update, ...rest}: Props) => {
  return (
    <SlideHandler<number> {...{initialValue, update}}>
      {(value, onValueChange) => (
        <StyledSlider
          {...rest}
          title="TEMPERATURE"
          message={`${value}°`}
          fontColor={appColors.white}
          bottomFontColor={appColors.white50}
          containerWidth="100%"
          paddingHorizontal={7}
          step={1}
          numberFormat={v => `${v}°`}
          maximumTrackTintColor={appColors.transluscentLightGray}
          minimumTrackTintColor={appColors.white}
          thumbTintColor={appColors.white}
          color={appColors.white50}
          onSlidingComplete={onValueChange}
          {...{value, onValueChange}}
        />
      )}
    </SlideHandler>
  );
};
