import {HeaderBackButtonProps} from '@react-navigation/elements';
import {useIsFocused} from '@react-navigation/native';
import cloneDeep from 'lodash/cloneDeep';
import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';
import {ulid} from 'ulid';

import {
  BackButton,
  BottomSheetButtonProps,
  BottomSheetButtonTitle,
  BottomSheetComponent,
  BottomSheetDetail,
  GlowingHalo,
  HeatProfileScreenBaseBackground,
  MultiGradientBackground,
  ProfileDisplay,
  StyledButton,
  StyledIcon,
  useSpinner,
} from '../../components';
import {
  Alerts,
  Constants,
  ErrorMessages,
  Navigators,
  Screens,
  Strings,
  appColors,
  centerStyle,
  interpolate,
} from '../../constants';
import {
  UpdateHeatProfileDto,
  updateHeatProfiles,
  useUpdateHeatProfiles,
} from '../../lib/api';
import {shareApi} from '../../lib/api/apis';
import {
  useAppDispatch,
  useAppHeaderHeight,
  useChamberType,
  useLantern,
  useUpdateDabbingReduxValues,
  useUserVerifiedGuard,
  useWatchDevice,
} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  convertToPreferredUnits,
  enforceProfileOrder,
  profilesMatchRawValues,
} from '../../lib/profileFunctions';
import {appFlagsSelector} from '../../lib/redux/appFlagsSlice';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {
  connectedPeakSelector,
  currentDeviceSelector,
} from '../../lib/redux/bleSlice';
import {getMoodLightSelector} from '../../lib/redux/moodLightSlice';
import {
  activeProfilesSelector,
  addArchiveProfile,
  archiveProfilesSelector,
  currentProfileSelector,
  setArchiveProfiles,
} from '../../lib/redux/profilesSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {
  HeatProfileType,
  MoodLight,
  Profile,
  isExclusiveMoodLight,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useTheme} from '../../lib/useTheme';
import {meetsMinimumFirmware} from '../../lib/utilityFunctions';
import {shareUrl} from '../../lib/utilityFunctions/shareUrl';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigation/navigators/params';
import {toHeatProfileEdit} from '../../navigation/navigators/util';
import {Carousel} from '../../shims/Carousel';
import {Alert} from '../../shims/alert';
import {analytics} from '../../src/services/analytics';
import {getAppUrl} from '../../src/util/url';
import {
  blackPeachTheme,
  desertTheme,
  opalTheme,
  whitePeachTheme,
} from '../../themes';
import {useCanDab} from './useCanDab';

export type HeatProfileRenderItemType = Profile & {
  width?: number;
  height: number;
};

const WIDTH = Dimensions.get('window').width;
const INDICATOR_HEIGHT = 92;

interface Props
  extends HomeEmulatedDrawerStackScreenProps<
    typeof Screens.HeatProfileSelect
  > {}

export const HeatProfileSelectScreen = ({route, navigation}: Props) => {
  useDisconnectGuard();

  const theme = useTheme();
  const {
    navMenuIconColor,
    navMenuTitleStyle,
    heatProfileSelectScreenTheme,
    styledButtonTheme,
    bottomSheetTheme,
    alternateSpinnerColor,
  } = theme;
  const {primaryColor, backgroundLineColor} = heatProfileSelectScreenTheme;
  const {gradientShadeFunction: iconGradientFunction, IconBaseBackground} =
    bottomSheetTheme;

  const dispatch = useAppDispatch();
  const [{error, value, loading: isLoadingHeatProfiles}, updateApiArchives] =
    useUpdateHeatProfiles();

  const user = useSelector(userSelector);
  const peak = useSelector(connectedPeakSelector);
  const device = useSelector(currentDeviceSelector);

  const {verifyCanDab} = useCanDab();

  const {
    type = HeatProfileType.Active,
    profile,
    titleStyle,
    iconColor,
  } = route.params ?? {};

  const isActive = type === HeatProfileType.Active;

  const appSettings = useSelector(appSettingsSelector);
  const {hasSeenVaporUnlockedAlert} = useSelector(appFlagsSelector);

  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T
  const profiles = useSelector(
    isActive ? activeProfilesSelector : archiveProfilesSelector,
  );
  const archiveProfiles = useSelector(archiveProfilesSelector);
  const currentActiveProfile = useSelector(currentProfileSelector);

  const index = isActive
    ? profile
      ? profile.order
      : currentActiveProfile.order
    : profiles.findIndex((p: Profile) => p.id === profile?.id) ?? 0;

  React.useEffect(() => {
    if (!peak || !profile) return;

    if (isActive) {
      peak?.writeHeatProfileSelect(profile.order);
    } else {
      peak?.writeTempHeatProfile(
        profile,
        isTHeatProfileMoodLight(profile)
          ? getMoodLight(profile.moodLightId)
          : undefined,
      );
    }
  }, [peak, profile, isActive]);

  const {isVaporEnabled} = useChamberType();
  useWatchDevice('heatCycleSelect', true, 0);

  const currentProfile = isActive
    ? currentActiveProfile
    : profiles[index] ?? currentActiveProfile;

  const isOpal = opalTheme === theme;
  const isPeachOrDesert =
    theme === whitePeachTheme ||
    theme === blackPeachTheme ||
    theme === desertTheme;
  const moodLightColors = React.useMemo(() => {
    const currentMoodLight = profiles[index] ?? currentActiveProfile;
    return isTHeatProfileMoodLight(currentMoodLight)
      ? getMoodLight(currentMoodLight.moodLightId)?.colors ?? [
          appColors.defaultColor,
        ]
      : [currentMoodLight.color];
  }, [profiles, index]);

  const [profileUpdate, setProfileUpdate] = React.useState<Profile>();
  const HEADER_HEIGHT = useAppHeaderHeight();
  const [dabLoading, setDabLoading] = React.useState(false);

  const shareProfileById = async (shareId: string) => {
    const url = `${getAppUrl()}/${Constants.SHARE_PATH}/${shareId}`;

    await shareUrl({url})
      .then(() => {
        analytics.trackEvent('heat profile share', {});
      })
      .catch(() => void 0);
  };

  const createShareProfile = (
    currentProfile: Profile,
    tempPreference: TemperatureUnit,
    defaultColor: string,
  ) =>
    shareApi.createShareProfileId({
      heatProfileCreateShareDto: {
        ...convertToPreferredUnits(currentProfile, tempPreference),
        color: isTHeatProfileMoodLight(currentProfile)
          ? defaultColor
          : currentProfile.color,
      },
    });

  useLantern(
    isTHeatProfileMoodLight(currentProfile)
      ? getMoodLight(currentProfile.moodLightId)
      : currentProfile.color,
  );
  const updateDabbingReduxValues = useUpdateDabbingReduxValues();
  const isFocused = useIsFocused();
  useAdaptiveSafeArea();
  const [backgroundHeight, setBackgroundHeight] = React.useState<
    number | undefined
  >(undefined);

  const downloadProfile = React.useCallback(
    (heatProfile: UpdateHeatProfileDto) =>
      updateHeatProfiles([heatProfile]).then(data => {
        const profile = data[0];

        dispatch(addArchiveProfile(profile));

        return createShareProfile(
          profile,
          appSettings.tempPreference,
          appColors.defaultColor,
        );
      }),
    [appSettings.tempPreference],
  );

  const getProfileMoodLight = React.useCallback(
    (profile: Profile) => {
      return isTHeatProfileMoodLight(profile)
        ? getMoodLight(profile.moodLightId)
        : undefined;
    },
    [getMoodLight],
  );

  const {isUserVerified} = useUserVerifiedGuard();

  const [
    {loading, value: createdShare, error: createdShareError},
    onShareButtonPress,
  ] = useAsyncFn(
    async (pressed: boolean = true) => {
      if (!pressed) return;

      if (!user) {
        Alert.alert(
          Alerts.PROFILE_SHARE_TITLE,
          Alerts.PROFILE_LOGIN_SHARE_MESSAGE,
          [
            {
              text: Strings.LOG_IN,
              onPress: () => {
                navigation.navigate(Screens.Login, {
                  redirect: new RedirectionParameter(Navigators.MainNavigator, {
                    screen: Navigators.HomeDrawerNavigator,
                    params: {
                      screen: Navigators.HomeEmulatedDrawer,
                      params: {
                        screen: Screens.HeatProfileSelect,
                      },
                    },
                  }).encode(),
                });
              },
              style: 'default',
            },
            {text: Strings.CANCEL, style: 'cancel'},
          ],
          {cancelable: false},
        );

        return;
      }

      if (!isUserVerified()) return;

      const currentProfileMoodLight = getProfileMoodLight(currentProfile);
      const archiveProfileCopy = archiveProfiles.find(archiveProfile =>
        profilesMatchRawValues(
          archiveProfile,
          currentProfile,
          getProfileMoodLight(archiveProfile),
          currentProfileMoodLight,
        ),
      );

      if (isActive && !archiveProfileCopy) {
        const moodLightCopyId = ulid();
        const isExclusive = currentProfileMoodLight
          ? isExclusiveMoodLight(currentProfileMoodLight)
          : false;

        const updatedProfile: UpdateHeatProfileDto = {
          ...currentProfile,
          id: ulid(),
          userId: user.id,
          ...(isTHeatProfileMoodLight(currentProfile) &&
            !isExclusive && {
              moodLightId: moodLightCopyId,
              moodLight: {
                userId: user.id,
                ...(currentProfileMoodLight as MoodLight),
                id: moodLightCopyId,
              },
            }),
        };

        return downloadProfile(updatedProfile);
      } else {
        return createShareProfile(
          isActive ? archiveProfileCopy || currentProfile : currentProfile,
          appSettings.tempPreference,
          appColors.defaultColor,
        );
      }
    },
    [
      appSettings.tempPreference,
      archiveProfiles,
      currentProfile,
      downloadProfile,
      getProfileMoodLight,
      isActive,
      isUserVerified,
      user,
      navigation,
    ],
  );

  React.useEffect(() => {
    if (!createdShareError) return;

    Alert.alert(Alerts.PROFILE_SHARE_ERROR);
  }, [createdShareError]);

  React.useEffect(() => {
    if (!createdShare) return;

    const {shareId} = createdShare.data;

    Alert.alert(
      interpolate(Alerts.SHARE_PROFILE_READY_TITLE, {
        profileName: currentProfile.name,
      }),
      interpolate(Alerts.SHARE_PROFILE_READY_BODY, {
        profileName: currentProfile.name,
      }),
      [
        {
          style: 'cancel',
          text: 'Cancel',
        },
        {
          onPress: () => {
            shareProfileById(shareId);
          },
          text: 'Share',
        },
      ],
      {
        onDismiss: () => {
          onShareButtonPress(false);
        },
      },
    );
  }, [createdShare, currentProfile.name, onShareButtonPress]);

  const isLoading = isLoadingHeatProfiles || loading;

  useSpinner({
    isVisible: isLoading && isFocused,
    ...(isLoading && {
      text: loading ? Strings.PREPARING_PROFILE_SHARE : Strings.SAVING,
      color: alternateSpinnerColor,
    }),
  });

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: isActive !== false ? 'ACTIVE' : 'ARCHIVE',
      headerTitleStyle: {
        ...navMenuTitleStyle,
        ...(isPeachOrDesert && {color: appColors.white}),
      },
      headerLeft: ({disabled, onPress}: HeaderBackButtonProps) => (
        <BackButton
          {...{disabled, onPress}}
          iconStyle={{
            tintColor: isPeachOrDesert ? appColors.white : navMenuIconColor,
          }}
        />
      ),
      gestureEnabled: false,
    });
  }, [
    navigation,
    iconColor,
    isActive,
    titleStyle,
    navMenuTitleStyle,
    isPeachOrDesert,
    navMenuIconColor,
  ]);

  React.useEffect(() => {
    !isFocused && BottomSheetComponent.close();
  }, [isFocused]);

  React.useEffect(() => {
    if (!error && !!value && profileUpdate) {
      let newArchives = cloneDeep(profiles);
      newArchives[index] = cloneDeep(profileUpdate);
      newArchives = newArchives.filter(profile => !profile.deleted);
      enforceProfileOrder(newArchives);
      dispatch(setArchiveProfiles(newArchives));

      profileUpdate?.deleted &&
        navigation.navigate(Navigators.HomeTabNavigator, {
          screen: Screens.HeatProfileList,
          params: {},
        });

      setProfileUpdate(undefined);
    } else if (error) {
      Alert.alert(Alerts.NETWORK_ERROR, ErrorMessages.NETWORK_ERROR);
      setProfileUpdate(undefined);
    }
  }, [error, value, profileUpdate, navigation]);

  React.useEffect(() => {
    const buttonPropArr: BottomSheetButtonProps[] = [
      {
        title: BottomSheetButtonTitle.EDIT,
        iconName: 'settings',
        size: 20,
        onPress: () => {
          navigation.navigate(
            ...toHeatProfileEdit({profileId: profile?.id}).value,
          );
        },
      },
      {
        title: BottomSheetButtonTitle.SHARE,
        iconName: 'share',
        size: 28,
        onPress: () => onShareButtonPress(),
      },
      ...(!isActive && user
        ? [
            {
              title: BottomSheetButtonTitle.DELETE,
              iconName: 'delete' as const,
              size: 28,
              onPress: () =>
                Alert.alert(
                  Alerts.CONFIRM_DELETION,
                  interpolate(Alerts.CONFIRM_DELETE_PROFILE_BODY, {
                    profileName: currentProfile.name.toUpperCase(),
                  }),
                  [
                    {text: 'Cancel', style: 'cancel'},
                    {
                      text: 'OK',
                      onPress: () => {
                        const updatedProfile: UpdateHeatProfileDto = {
                          ...cloneDeep(currentProfile),
                          deleted: true,
                          userId: user.id,
                        };
                        setProfileUpdate(updatedProfile);
                        updateApiArchives([updatedProfile]);

                        analytics.trackEvent('heat profile delete', {});
                      },
                    },
                  ],
                  {cancelable: false},
                ),
            },
          ]
        : []),
    ];

    if (isActive) BottomSheetComponent.close();

    navigation.setOptions({
      headerRight: () => (
        <StyledIcon
          name={'more'}
          onPress={() =>
            !isLoading &&
            BottomSheetComponent.display(
              <BottomSheetDetail
                header={{
                  icon: (
                    <MultiGradientBackground
                      gradientShadeFunction={iconGradientFunction}
                      colors={moodLightColors}
                      style={{...centerStyle, width: 40, height: 40}}
                      {...(IconBaseBackground && {
                        BaseBackground: IconBaseBackground,
                      })}>
                      <GlowingHalo
                        colors={moodLightColors}
                        theme={theme}
                        seconds={currentProfile.duration}
                        glowWidthHeight={34}
                      />
                    </MultiGradientBackground>
                  ),
                  title: currentProfile.name,
                  subtitle: 'HEAT PROFILE',
                }}
                buttonPropArr={buttonPropArr}
              />,
            )
          }
          size={24}
          iconStyle={{
            tintColor: isPeachOrDesert ? appColors.white : navMenuIconColor,
          }}
        />
      ),
    });
  }, [
    IconBaseBackground,
    currentProfile,
    iconGradientFunction,
    index,
    isActive,
    isLoading,
    moodLightColors,
    navMenuIconColor,
    isVaporEnabled,
    hasSeenVaporUnlockedAlert,
    isPeachOrDesert,
    profile?.id,
    onShareButtonPress,
    theme,
    updateApiArchives,
    user,
    navigation,
  ]);

  const chooseProfile = React.useCallback(
    async (profile: Profile) => {
      if (dabLoading) return;

      verifyCanDab(async () => {
        if (isActive) {
          await peak?.writeHeatProfileSelect(profile.order);
          updateDabbingReduxValues(profile);
          setDabLoading(true);

          peak?.startDabbing(profile.order);
          setDabLoading(false);
          navigation.navigate(Screens.Dabbing);
        } else {
          if (
            !meetsMinimumFirmware(
              device?.softwareRevisionString,
              Constants.MINIMUM_FIRMWARE_VERSION.TEMP_PROFILE,
            )
          ) {
            return Alert.alert(
              Alerts.UPDATE_FIRMWARE,
              Alerts.UPDATE_FIRMWARE_BODY,
            );
          }

          await peak?.writeTempHeatProfile(
            profile,
            isTHeatProfileMoodLight(profile)
              ? getMoodLight(profile.moodLightId)
              : undefined,
          );

          await peak?.writeHeatProfileSelect(Constants.TEMP_HEAT_PROFILE_INDEX);

          updateDabbingReduxValues(profile);
          setDabLoading(true);

          peak?.startDabbing(Constants.TEMP_HEAT_PROFILE_INDEX);
          setDabLoading(false);
          navigation.navigate(Screens.Dabbing, {
            archiveProfile: profile,
          });
        }
      });
    },
    [
      dabLoading,
      peak,
      device,
      verifyCanDab,
      getMoodLight,
      isActive,
      updateDabbingReduxValues,
      navigation,
    ],
  );

  const renderItem = React.useCallback(
    (data: HeatProfileRenderItemType) => (
      <ProfileDisplay
        profile={data}
        moodLight={
          isTHeatProfileMoodLight(data)
            ? getMoodLight(data.moodLightId)
            : undefined
        }
        theme={theme}
        key={data.id}
        height={data.height}
        width={data?.width}
        isAnimating={currentProfile.order === data.order}
        disabled={isLoading}
        onPress={() => chooseProfile(data)}
        isOpal={isOpal}
        indicatorHeight={INDICATOR_HEIGHT}
        isVaporEnabled={isVaporEnabled}
      />
    ),
    [
      chooseProfile,
      currentProfile,
      getMoodLight,
      isLoading,
      isOpal,
      theme,
      isVaporEnabled,
    ],
  );

  const renderArchiveItem = React.useCallback(
    (data: HeatProfileRenderItemType) => (
      <ProfileDisplay
        key={currentProfile?.id}
        profile={currentProfile}
        moodLight={
          isTHeatProfileMoodLight(data)
            ? getMoodLight(data.moodLightId)
            : undefined
        }
        theme={theme}
        height={data.height}
        width={data?.width}
        disabled={isLoading}
        onPress={() => chooseProfile(currentProfile)}
        isAnimating={currentProfile?.id === profiles[index]?.id}
        isOpal={isOpal}
        indicatorHeight={INDICATOR_HEIGHT}
        isVaporEnabled={isVaporEnabled}
      />
    ),
    [
      chooseProfile,
      currentProfile,
      getMoodLight,
      index,
      isLoading,
      isOpal,
      profiles,
      theme,
      isVaporEnabled,
    ],
  );

  return (
    <HeatProfileScreenBaseBackground
      theme={theme}
      onLayout={e => {
        setBackgroundHeight(e.nativeEvent.layout.height);
      }}>
      {backgroundHeight && (
        <ContentContainer style={{height: backgroundHeight}}>
          {backgroundHeight && (
            <Carousel
              height={backgroundHeight}
              renderItem={isActive ? renderItem : renderArchiveItem}
              data={isActive ? profiles : [currentProfile]}
              onSnapToItem={(index: number) => {
                if (isActive && index !== currentActiveProfile.order) {
                  peak?.writeHeatProfileSelect(index).then(() => {
                    navigation.setParams({
                      profile: (isActive ? profiles : [currentProfile])[index],
                    });
                  });
                }
              }}
              loop={isActive ? Constants.IS_WEB : false}
              currentParentIndex={isActive ? index : 0}
            />
          )}

          <HeaderCover style={{height: HEADER_HEIGHT}} />

          <FooterContainer
            style={{
              marginTop: backgroundHeight - INDICATOR_HEIGHT,
            }}>
            {isActive ? (
              <ScrollIndicatorContainer>
                {profiles.map((_, i) => {
                  return (
                    <Line
                      key={i}
                      style={{
                        backgroundColor:
                          i === index ? primaryColor : backgroundLineColor,
                      }}
                    />
                  );
                })}
              </ScrollIndicatorContainer>
            ) : (
              <StyledButton
                title={'SAVE TO DEVICE'}
                disabled={isLoading}
                theme={styledButtonTheme}
                onPress={() =>
                  navigation.navigate(Screens.SaveToDevice, {
                    archiveIndex: index,
                  })
                }
              />
            )}
          </FooterContainer>
        </ContentContainer>
      )}
    </HeatProfileScreenBaseBackground>
  );
};

const HeaderCover = styled(View)({
  position: 'absolute',
  width: '100%',
  backgroundColor: appColors.invisible,
});

const ContentContainer = styled(View)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const FooterContainer = styled(View)({
  display: 'flex',
  height: INDICATOR_HEIGHT,
  width: WIDTH,
  alignItems: 'center',
  position: 'absolute',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const Line = styled(View)({
  width: 25,
  height: 2,
  marginHorizontal: 3.5,
});

const ScrollIndicatorContainer = styled(View)({
  height: 45,
  flexDirection: 'row',
  alignItems: 'center',
});
