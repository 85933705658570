import {Linking} from 'react-native';

import {analytics} from '../../../../../src/services/analytics';

export const onItemPress = (onPress?: () => void, link?: string) =>
  onPress
    ? onPress()
    : link
      ? Linking.openURL(link).then(() =>
          analytics.trackEvent('social link click', {url: link}),
        )
      : undefined;
