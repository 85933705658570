import React from 'react';

import {StyledSlider, StyledSliderProps} from '../../components';
import {Messages, appColors} from '../../constants';
import {Vapor} from '../../lib/types';
import {HandlerProps, SlideHandler} from '../components';
import {getVaporSnapValue} from './lib/getVaporSnapValue';

export interface Props
  extends Pick<HandlerProps<number>, 'initialValue' | 'update'>,
    Pick<StyledSliderProps, 'enabled' | 'titleMarginBottom'> {
  vapors: Vapor[];
}

// Slider doesn't work with decimal numbers, so we multiply by 10 to obtain integers from the Vapor values (0.5 => 5)
const FACTOR = 10;

export const VaporControlSlider = ({
  initialValue,
  enabled,
  update,
  vapors,
  ...rest
}: Props) => {
  const handleGetVaporValue = (v: number) => {
    return getVaporSnapValue(v / FACTOR, vapors);
  };

  return (
    <SlideHandler<number> {...{initialValue, update}}>
      {(value, onValueChange) => {
        const maxValue = vapors[vapors.length - 1];

        if (value > maxValue) onValueChange(maxValue);

        return (
          <StyledSlider
            {...rest}
            paddingBottom={0}
            title="VAPOR CONTROL"
            titleIcon={!enabled ? 'lock' : undefined}
            message={`${Messages.VAPOR[value as Vapor]?.toUpperCase()}`}
            fontColor={appColors.white}
            bottomFontColor={appColors.white50}
            containerWidth="100%"
            paddingHorizontal={7}
            step={1}
            maximumValue={vapors[vapors.length - 1] * FACTOR}
            minimumValue={vapors[0] * FACTOR}
            numberFormat={val => Vapor[val]}
            maximumTrackTintColor={appColors.transluscentLightGray}
            minimumTrackTintColor={appColors.white}
            thumbTintColor={appColors.white}
            color={appColors.white}
            value={value * FACTOR}
            onValueChange={(v: number) => onValueChange(handleGetVaporValue(v))}
            onSlidingComplete={(v: number) => {
              onValueChange(handleGetVaporValue(v));
            }}
            onAutoSlide={(v: number) =>
              (handleGetVaporValue(v) * 100) / vapors[vapors.length - 1]
            }
            tickers={vapors.map(v => v.toString())}
            nTickers={vapors.length}
            {...{enabled}}
          />
        );
      }}
    </SlideHandler>
  );
};
