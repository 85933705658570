import React from 'react';

import {Screens} from '../../constants';
import {HeatProfileEditNavigator} from '../../navigation/navigators/RootStackNavigator';
import {analytics} from '../../src/services/analytics';
import {HeatProfileForm} from './HeatProfileForm';

interface Props
  extends HeatProfileEditNavigator<typeof Screens.HeatProfileCreate> {}

export const HeatProfileCreateScreen = (props: Props) => {
  const onSave = React.useCallback(() => {
    analytics.trackEvent('heat profile create', {});
  }, []);

  return <HeatProfileForm {...{onSave}} {...props} />;
};
