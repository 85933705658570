import React from 'react';
import {StyleSheet, View} from 'react-native';

import {Modal} from '../../../../components';
import {resolveAssetUrl} from '../../../../lib/api';
import {
  TypeGallery,
  TypeStyledAsset,
  isTypeGallery,
  isTypeStyledAsset,
} from '../../../../lib/api/content-access/types';
import {ContestGalleryViewer} from './GalleryViewer';
import {GridGalleryItem} from './GridGalleryItem';

interface Props {
  gallery: TypeGallery;
}

export const ContestGallery: React.FC<Props> = ({gallery}) => {
  const elements = gallery.fields.elements;

  if (!elements) return;

  return (
    <View style={styles.gridContainer}>
      {elements.map((asset, index) => {
        // Contest gallery only accepts galleries of styled assets as elements
        if (!isTypeGallery(asset)) return;

        const innerElements = asset.fields.elements as TypeStyledAsset[];

        if (!innerElements) return;

        const firstAsset = innerElements[0];

        if (!isTypeStyledAsset(firstAsset)) return;

        const thumbnail = resolveAssetUrl(firstAsset.fields.asset);

        return (
          <GridGalleryItem
            {...{asset, index, thumbnail}}
            key={index}
            onPress={() =>
              Modal.display({
                element: (
                  <ContestGalleryViewer
                    {...{index}}
                    elements={elements as TypeGallery[]}
                    onClose={Modal.close}
                    guide={gallery.fields.guide}
                  />
                ),
              })
            }
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 14,
  },
});
