import {
  background,
  logoClose,
  peak,
  peakLightsBaseLeft,
  peakLightsBaseRight,
  peakLightsGlassLeft,
  peakLightsGlassRight,
} from '../assets/images';
import {appColors} from '../constants/Colors';
// Import from exact file to prevent cycle
import {shadeColor} from '../lib/shadeColor';
import {
  AssignToAHeatProfileScreenTheme,
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  CustomSwitchTheme,
  DabbingScreenTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LanternTypeButtonTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  SaveToDeviceScreenTheme,
  SharedMoodScreenTheme,
  StatusBarStyle,
  StatusBarTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from '../lib/types';
import {addOpacityToColorHex} from '../lib/utilityFunctions/addOpacityToColorHex';

// Import from exact file to prevent cycle

// General

const navMenuTitleStyle: NavMenuTitleStyle = {
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 14,
  color: appColors.white,
};

// Components

const bottomSheetTheme: BottomSheetTheme = {
  buttonBackgroundColor: '#353535',
  buttonTextColor: appColors.white,
  backgroundColor: appColors.bottomSheetGray,
  dividerColor: '#BCBBC1',
  lineColor: '#BCBBC1',
  titleColor: appColors.white,
  subtitleColor: appColors.white50,
  iconColor: appColors.white,
  gradientShadeFunction: (color: string) =>
    [-50, -80].map(shade => shadeColor(color, shade)),
};

const customSwitchTheme: CustomSwitchTheme = {
  backgroundColorOn: appColors.white,
  backgroundColorOff: appColors.black,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  primaryFontColor: appColors.white,
  durationFontColor: appColors.white,
  durationBubbleColor: appColors.black,
  specularColorStart: appColors.black,
  specularColorEnd: '#FFFFFF26',
  iconColor: appColors.white,
  gradientShadeFunction: (color: string) =>
    [-75, -90].map(shade => shadeColor(color, shade)),
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  gradientShadeFunction: (color: string) =>
    [-60, -90].map(shade => shadeColor(color, shade)),
};

const lanternTypeButtonTheme: LanternTypeButtonTheme = {
  activeForeground: appColors.black,
  inactiveForeground: appColors.white,
  activeBackground: '#D4D4D4',
  inactiveBackground: appColors.black50,
};

const moodLightCardTheme: MoodLightCardTheme = {
  gradientShadeFunction: (color: string) =>
    [-75, -90].map(shade => shadeColor(color, shade)),
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  activeForeground: appColors.black,
  inactiveForeground: appColors.white,
  activeBackground: appColors.white,
  inactiveBackground: appColors.black,
  textColor: appColors.white,
};

const peakImageTheme: PeakImageTheme = {
  peak,
  lightLayers: {
    glass: {
      left: peakLightsGlassLeft,
      right: peakLightsGlassRight,
    },
    base: {
      left: peakLightsBaseLeft,
      right: peakLightsBaseRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDisconnectedColor: appColors.red,
  peakDarkScreen: peak,
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: appColors.bone,
  buttonTextColor: appColors.black,
};

const tabBarTheme: TabBarTheme = {
  activeColor: appColors.white,
  inactiveColor: appColors.mediumGray,
  backgroundColor: appColors.background,
  height: 50,
};

// Screens

const assignToAHeatProfileScreenTheme: AssignToAHeatProfileScreenTheme = {
  selectedBorderColor: appColors.white,
  background: {
    color: appColors.bottomSheetGray,
  },
};

const connectScreenTheme: ConnectScreenTheme = {
  background: {image: background},
  peakNameBackgroundColor: appColors.black,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: appColors.white,
  infoText: appColors.white50,
  fatSliderColor: appColors.white,
  fatSliderBGColor: appColors.black,
  buttonActiveForeground: appColors.black,
  buttonInactiveForeground: appColors.white,
  buttonActiveBackground: appColors.white,
  buttonInactiveBackground: appColors.black50,
  blurBackgroundColor: appColors.transluscentLightGray,
  background: {
    color: appColors.bottomSheetGray,
  },
  boostScreenLabel: appColors.baseText,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: appColors.white,
  dabbingScreenFadedText: appColors.mediumGray50,
  dabbingButtonBackground: appColors.bone,
  dabbingButtonBackgroundPressed: appColors.black,
  dabbingButtonForeground: appColors.black,
  dabbingButtonForegroundPressed: appColors.bone,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  cardColor: appColors.black50,
  cardText: appColors.white,
  listTitleText: appColors.white,
  listDivider: '#888888',
  trashCanIconColor: appColors.white,
  activeOpacity: 1,
  inactiveOpacity: 0.2,
  connectedIconColor: appColors.connectedGreen,
  disconnectedIconColor: '#D0021B',
  blurBackgroundColor: appColors.clear,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  editTextColor: appColors.lightSmoke,
  sliderTitleColor: appColors.white,
  sliderThumbColor: appColors.white,
  sliderTrackFillColor: appColors.white,
  sliderTrackBackgroundColor: appColors.white30,
  sliderIconTintColor: appColors.white,
  moodLightHeaderBackgroundColor: appColors.darkGreyMedium,
  durationColor: appColors.white50,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {image: background},
  hoverBackgroundColor: appColors.white,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: appColors.white,
  durationColor: appColors.white50,
  startTextStyle: {color: appColors.white, fontWeight: '400'},
  backgroundLineColor: appColors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  // General
  background: {image: background},
  peakDisconnectedColor: 'transparent',

  // Status info
  batteryBarBackgroundColor: appColors.progressBarGray,
  batteryBarForegroundColor: appColors.batteryGreen,
  batteryDisconnectedColor: addOpacityToColorHex(appColors.white, 0.2),
  preserveIndicator: appColors.white,
  statusTextConnectedColor: appColors.darkGreen,
  statusTextDisconnectedColor: appColors.red,
  statusTextStealthColor: appColors.white,
  statusTitleTextColor: appColors.white,
  stealthIconColor: appColors.white,

  // Metric info
  dataBackgroundGradientArray: [
    {color: addOpacityToColorHex(appColors.black, 0.3), location: 0},
    {color: addOpacityToColorHex(appColors.black, 0), location: 1},
  ],
  bowlWarningColor: appColors.red,
  metricDisconnectedTextColor: addOpacityToColorHex(appColors.white, 0.2),
  metricTextColor: appColors.white,
  metricTitleTextColor: appColors.white50,
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: heatProfileListScreenTheme.background,
  infoLabelColor: appColors.white50,
};

const sharedMoodScreenTheme: SharedMoodScreenTheme = {
  gradientShadeFunction: (color: string) =>
    [-30, -30].map(shade => shadeColor(color, shade)),
};

export const LightStatusBarTheme: StatusBarStyle = {
  backgroundColor: appColors.white,
  barStyle: 'dark-content',
};
export const DarkStatusBarTheme: StatusBarStyle = {
  backgroundColor: '#090909',
  barStyle: 'light-content',
};

const statusBarTheme: StatusBarTheme = {
  dark: DarkStatusBarTheme,
  light: LightStatusBarTheme,
  themed: DarkStatusBarTheme,

  // These screens' background is always light
  WelcomeScreenStatusBar: LightStatusBarTheme,
  LegalIntroScreenStatusBar: LightStatusBarTheme,
  PrivacyPolicyScreenStatusBar: LightStatusBarTheme,
  TermsConditionsScreenStatusBar: LightStatusBarTheme,
  AccountCreatedScreenStatusBar: LightStatusBarTheme,
  CarouselScreenStatusBar: LightStatusBarTheme,
  EmailConfirmedScreenStatusBar: LightStatusBarTheme,
  ForgotPasswordScreenStatusBar: LightStatusBarTheme,
  LoginScreenStatusBar: LightStatusBarTheme,
  PasswordConfirmedScreenStatusBar: LightStatusBarTheme,
  PasswordResetScreenStatusBar: LightStatusBarTheme,
  PasswordResetSentScreenStatusBar: LightStatusBarTheme,
  RegisterScreenStatusBar: LightStatusBarTheme,
  XLUpdateRequiredScreenStatusBar: LightStatusBarTheme,
  GuideScreenStatusBar: LightStatusBarTheme,
  GuideStepScreenStatusBar: LightStatusBarTheme,

  // These screens' background is always dark
  CleaningReminderScreenStatusBar: DarkStatusBarTheme,
  AccountScreenStatusBar: DarkStatusBarTheme,
  DevicesListScreenStatusBar: DarkStatusBarTheme,
  PreferencesScreenStatusBar: DarkStatusBarTheme,
  SupportScreenStatusBar: DarkStatusBarTheme,
  HeatProfileSelectScreenStatusBar: DarkStatusBarTheme,
  HeatProfileEditScreenStatusBar: DarkStatusBarTheme,
  DabbingScreenStatusBar: DarkStatusBarTheme,
  ContactUsScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenMessageStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenSuccessStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenTwoStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenUserInfoStatusBar: DarkStatusBarTheme,
  ConnectScreenStatusBar: DarkStatusBarTheme,
  PairInstructionsScreenStatusBar: DarkStatusBarTheme,
  LimitedEditionModalScreenStatusBar: DarkStatusBarTheme,
  AdvancedMetricsScreenStatusBar: DarkStatusBarTheme,
  MoodLightControllerScreenStatusBar: DarkStatusBarTheme,
  MoodLightAnimationScreenStatusBar: DarkStatusBarTheme,
  MoodLightLibraryScreenStatusBar: DarkStatusBarTheme,
  AssignToAHeatProfileScreenStatusBar: DarkStatusBarTheme,
  BluetoothStartPairingScreenStatusBar: DarkStatusBarTheme,
  ChangeEmailScreenStatusBar: DarkStatusBarTheme,
  CleaningManualScreenStatusBar: DarkStatusBarTheme,
  ChangePasswordScreenStatusBar: DarkStatusBarTheme,
  ChangeUsernameScreenStatusBar: DarkStatusBarTheme,
  ChangeNameScreenStatusBar: DarkStatusBarTheme,
  ControlCenterBoostScreenStatusBar: DarkStatusBarTheme,
  DownloadSharedHeatProfileScreenStatusBar: DarkStatusBarTheme,
  EmailVerifyScreenStatusBar: DarkStatusBarTheme,
  EpilepsyWarningScreenStatusBar: DarkStatusBarTheme,
  ExclusiveMoodScreenStatusBar: DarkStatusBarTheme,
  FirmwareInfoScreenStatusBar: DarkStatusBarTheme,
  FirmwareUpdatingScreenStatusBar: DarkStatusBarTheme,
  LanternModeScreenStatusBar: DarkStatusBarTheme,
  MarketOptInConfirmedScreenStatusBar: DarkStatusBarTheme,
  UserManualScreenStatusBar: DarkStatusBarTheme,
  SessionReadyScreenStatusBar: DarkStatusBarTheme,
  SharedMoodScreenStatusBar: DarkStatusBarTheme,
  SharedHeatProfileScreenStatusBar: DarkStatusBarTheme,
  SnoozeFrequencyScreenStatusBar: DarkStatusBarTheme,
  ReminderFrequencyScreenStatusBar: DarkStatusBarTheme,
  TemperatureSettingsScreenStatusBar: DarkStatusBarTheme,
  FactoryResetScreenStatusBar: DarkStatusBarTheme,
  MarketOptInExistScreenStatusBar: DarkStatusBarTheme,
  LimitedEditionVideoScreenStatusBar: DarkStatusBarTheme,
  SaveToDeviceScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenLogsStatusBar: DarkStatusBarTheme,
  HowToVideosScreenStatusBar: DarkStatusBarTheme,
  DeviceSupportScreenStatusBar: DarkStatusBarTheme,
  FAQScreenStatusBar: DarkStatusBarTheme,
  DeviceDocumentScreenStatusBar: DarkStatusBarTheme,
  ReferAFriendScreenStatusBar: DarkStatusBarTheme,
  MyRewardsScreenStatusBar: DarkStatusBarTheme,
  ReferralWelcomeScreenStatusBar: DarkStatusBarTheme,
};

export const defaultTheme: Theme = {
  // General
  fadedTextColor: appColors.white,
  logoIcon: logoClose,
  navMenuIconColor: appColors.white,
  navMenuTitleStyle,
  primaryColor: appColors.white,
  primaryTextColor: appColors.white,
  spinnerColor: appColors.white,

  // Components
  bottomSheetTheme,
  customSwitchTheme,
  heatProfileCardTheme,
  heatProfileScreenBackgroundTheme,
  lanternTypeButtonTheme,
  moodLightCardTheme,
  moodTypeButtonTheme,
  peakImageTheme,
  sectionDividerColor: appColors.gray,
  sectionTitleTextColor: appColors.white,
  statusDisplayTextColor: appColors.white,
  styledButtonTheme,
  tabBarTheme,
  statusBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileListScreenTheme,
  heatProfileSelectScreenTheme,
  homeScreenTheme,
  saveToDeviceScreenTheme,
  sharedMoodScreenTheme,
};
