import {Environment} from '../../../constants';
import {appLog} from '../../../lib/Logger';
import {EventProperties, EventType} from './event-properties';
import {FirebaseAnalytics} from './firebase';
import {Analytics} from './types';
import {UserProperties} from './user-properties';
import {UserTrackerCallback} from './user-tracker';

class AnalyticsService implements Analytics {
  constructor(private providers: Analytics[]) {}

  private executeOnProvider(callback: (provider: Analytics) => void) {
    this.providers.forEach(provider => {
      try {
        callback(provider);
      } catch (error) {
        appLog.error('Could not execute callback on analytic provider', {
          provider,
          error,
        });
      }
    });
  }

  trackEvent<E extends EventType>(
    type: E,
    event: EventProperties[E],
    userProperties?: Partial<UserProperties>,
  ): void {
    this.executeOnProvider(provider =>
      provider.trackEvent(type, event, userProperties),
    );
  }

  setCurrentUser(userId?: string): void {
    this.executeOnProvider(provider => provider.setCurrentUser(userId));
  }

  trackUser(callback: UserTrackerCallback): void {
    this.executeOnProvider(provider => provider.trackUser(callback));
  }

  setEnabled(enabled: boolean): void {
    this.executeOnProvider(provider => provider.setEnabled(enabled));
  }
}

// Disable analytics in dev env
const initialize = Environment.environment !== 'dev';

export const analytics = new AnalyticsService([
  ...(initialize ? [new FirebaseAnalytics()] : []),
]);
