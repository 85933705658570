import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {Title} from '../../../components';

interface Props {
  title?: string;
  style?: StyleProp<ViewStyle>;
}

export const Section = ({
  title,
  style,
  children,
}: React.PropsWithChildren<Props>) => (
  <View style={[styles.container, style]}>
    {!!title && <Title style={styles.title}>{title}</Title>}

    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    marginBottom: 48,
  },
  title: {
    fontSize: 14,
    textTransform: 'uppercase',
    opacity: 0.5,
    marginBottom: 16,
  },
});
