import React from 'react';

import {rainbow} from '../../assets/images';
import {StyledSlider} from '../../components';
import {Constants, appColors} from '../../constants';
import {convertHexToHsl} from '../../lib/convertHexToHsl';
import {convertHslToHex} from '../../lib/convertHslToHex';
import {HandlerProps, SlideHandler} from '../components';

export interface Props
  extends Pick<HandlerProps<string[]>, 'initialValue' | 'update'> {}

export const ColorSlider = ({initialValue, update}: Props) => {
  return (
    <SlideHandler<string[]> {...{initialValue, update}} slideAutoUpdate={true}>
      {(value, onValueChange) => (
        <StyledSlider
          trackImage={rainbow}
          maximumValue={0.999}
          minimumValue={0.001}
          thumbTintColor={appColors.white}
          color={appColors.white}
          bottomFontColor={appColors.white50}
          containerWidth="100%"
          paddingHorizontal={7}
          value={convertHexToHsl(value[0])}
          onValueChange={v =>
            onValueChange([
              convertHslToHex(
                v,
                Constants.STD_HSL_VALUES.saturation,
                Constants.STD_HSL_VALUES.lightness,
                true,
              ),
            ])
          }
          onSlidingComplete={v =>
            onValueChange([
              convertHslToHex(
                v,
                Constants.STD_HSL_VALUES.saturation,
                Constants.STD_HSL_VALUES.lightness,
                true,
              ),
            ])
          }
          noHeader={true}
          tickers={[]}
        />
      )}
    </SlideHandler>
  );
};
