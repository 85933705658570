import React from 'react';

import {StyledSlider, StyledSliderProps} from '../../components';
import {Constants, appColors} from '../../constants';
import {getTimeFormat} from '../../lib/getTimeFormat';
import {HandlerProps, SlideHandler} from '../components';

export interface Props
  extends Pick<HandlerProps<number>, 'initialValue' | 'update'>,
    Pick<StyledSliderProps, 'paddingBottom' | 'titleMarginBottom'> {}

const timeFormat = (val: number) =>
  `${Math.floor(val / 60)}:${`${val % 60}`.padStart(2, '0')}`;

export const DurationSlider = ({initialValue, update, ...rest}: Props) => {
  return (
    <SlideHandler<number> {...{initialValue, update}}>
      {(value, onValueChange) => (
        <StyledSlider
          {...rest}
          title="DURATION"
          message={getTimeFormat(value, {
            padMinutes: false,
            padSeconds: true,
          })}
          fontColor={appColors.white}
          bottomFontColor={appColors.white50}
          containerWidth="100%"
          step={1}
          paddingHorizontal={7}
          maximumValue={Constants.DURATION_MAX}
          minimumValue={Constants.DURATION_MIN}
          numberFormat={timeFormat}
          maximumTrackTintColor={appColors.transluscentLightGray}
          minimumTrackTintColor={appColors.white}
          thumbTintColor={appColors.white}
          color={appColors.white}
          onSlidingComplete={onValueChange}
          {...{value, onValueChange}}
        />
      )}
    </SlideHandler>
  );
};
